




import { Component, Vue } from 'vue-property-decorator'
import { Logs } from '@/api'

@Component({
  components: {
    LogsTable: () => import('./LogsTable.vue')
  }
})
export default class UserCouponsLogs extends Vue {
  data = []

  async mounted() {
    const userId = this.$route.params.id
    const couponLogs = userId
      ? await Logs.getUserCouponLogsById(userId)
      : await Logs.getCouponLogs()

    this.data = couponLogs.map(e => {
      return {
        id: e.id,
        couponName: e.coupon,
        ...(userId && { accountId: e.accountId }),
        claimingDate: new Date(e.lastUpdate).toUTCString(),
        claimedOn: e.characterName ?? 'Not claimed yet',
        ...(userId && { ipAddress: e.ipAddress })
      }
    })
  }
}
